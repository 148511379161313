@use 'dawuti.config' as dawuti;
@use 'theme/mixins' as mixins;
:root {
  --button-height: 40px;
  --button-small-height: 27px;
  --buttons-font-size: var(--font-size-small);
  --buttons-color: var(--color-primary);
}

ion-button.button,
ion-menu-toggle.button,
a.button,
button.button {
  // Primary
  cursor: pointer;

  width: fit-content;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  height: var(--button-height);
  padding: 0 calc(var(--s) * 3);

  font-family: var(--font-primary);
  font-size: var(--buttons-font-size);
  line-height: var(--button-height);
  font-weight: 500;
  text-decoration: none;
  letter-spacing: var(--letter-spacing-narrow);

  background-color: var(--color-primary);
  color: var(--color-light);

  border: none;
  border-radius: var(--button-height);

  > span {
    color: inherit;
  }

  @include mixins.defaultTransition();

  .dynicon {
    @include mixins.defaultTransition();
    --color: var(--color-light);
    --size: 18px;
    margin-left: calc(-1 * var(--s));
    margin-right: var(--s);
  }

  span {
    @include mixins.defaultTransition();
  }

  span + .dynicon {
    margin-left: var(--s);
    margin-right: calc(-1 * var(--s));
  }

  &:not([disabled]):hover {
    background-color: var(--color-button-hover);
  }

  &:not([disabled]):active {
    background-color: var(--color-button-focus);
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.23;
    .dynicon {
      opacity: 0.23;
    }
  }

  &.button--outline {
    border: var(--border);
    background-color: var(--color-light);
    color: var(--color-primary);

    .dynicon {
      --color: var(--color-primary);
    }

    &:not([disabled]):hover {
      background-color: var(--color-button-outline-hover);
      border: 1px solid var(--color-neutral-variant-50);
    }

    &:not([disabled]):active {
      background-color: var(--color-button-outline-focus);
      border: 1px solid var(--color-primary);
    }
  }

  // Card
  &.button--card {
    border: var(--border);
    background-color: var(--color-light);
    border-radius: var(--border-radius);
    padding: calc(var(--s) * 2);
    height: auto;
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    width: 100%;
    justify-content: flex-start;
    color: var(--color-text);
    .dynicon {
      --color: var(--color-primary);
      margin: 0;
    }

    &:not([disabled]):hover {
      background-color: var(--color-button-outline-hover);
      border: 1px solid var(--color-neutral-variant-50);
    }

    &:not([disabled]):active {
      background-color: var(--color-button-outline-focus);
      border: 1px solid var(--color-primary);
    }
  }

  &.button--card-emphasys {
    border: var(--border);
    background-color: var(--color-neutral-variant-90);
    border-radius: var(--border-radius);
    padding: calc(var(--s) * 2);
    height: auto;
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    width: 100%;
    justify-content: flex-start;
    color: var(--color-text);
    .dynicon {
      --color: var(--color-primary);
      margin: 0;
    }

    &:not([disabled]):hover {
      background-color: var(--color-button-card-emphasys-hover);
      border: 1px solid var(--color-neutral-variant-50);
    }

    &:not([disabled]):active {
      background-color: var(--color-button-card-emphasys-focus);
      border: 1px solid var(--color-primary);
    }
  }

  &.button--clear {
    background-color: transparent;
    color: var(--color-primary);

    .dynicon {
      --color: var(--color-primary);
    }

    &:not([disabled]):hover {
      background-color: var(--color-button-outline-hover);
    }

    &:not([disabled]):active {
      background-color: var(--color-button-outline-focus);
    }
  }

  &.button--clear-neutral {
    background-color: transparent;
    color: var(--color-text);

    .dynicon {
      --color: var(--color-text);
    }

    &:not([disabled]):hover {
      background-color: var(--color-button-outline-hover);
    }

    &:not([disabled]):active {
      background-color: var(--color-button-outline-focus);
    }
  }

  &.button--invisible {
    padding: 0;
    background-color: transparent;
    color: inherit;
    border-radius: 0;
  }

  &.button--elevated {
    background-color: var(--color-neutral-99);
    color: var(--color-primary);
    @include mixins.shadow-elevation-1;

    .dynicon {
      --color: var(--color-primary);
    }

    &:not([disabled]):hover {
      background-color: var(--color-button-outline-hover);
    }

    &:not([disabled]):active {
      background-color: var(--color-button-outline-focus);
    }
  }

  &.button--secondary {
    background-color: var(--color-secondary-90);
    color: var(--color-text);

    .dynicon {
      --color: var(--color-text);
    }

    &:not([disabled]):hover {
      background-color: var(--color-button-secondary-hover);
    }

    &:not([disabled]):active {
      background-color: var(--color-button-secondary-focus);
    }

    &.button--dark {
      background-color: var(--color-secondary-30);
      color: var(--color-light);

      .dynicon {
        --color: var(--color-light);
      }
    }
  }

  &.button--tertiary {
    background-color: var(--color-tertiary-70);
    color: var(--color-light);

    .dynicon {
      --color: var(--color-light);
    }

    &:not([disabled]):hover {
      background-color: var(--color-button-tertiary-hover);
    }

    &:not([disabled]):active {
      background-color: var(--color-button-tertiary-focus);
    }
  }

  &.button--icon {
    padding: 0;
    aspect-ratio: 1/1;
    .dynicon {
      margin: 0;
    }
  }

  &.button--tab {
    background-color: transparent;
    color: var(--color-text);

    &.button--selected {
      background-color: var(--color-neutral-variant-90);
      color: var(--color-text);
    }
    &:not([disabled]):hover {
      background-color: var(--color-neutral-variant-90);
      color: var(--color-text);
    }
  }

  &.button--link {
    border: none;
    background: none;
    color: var(--color-primary);
    text-align: center;
    padding: calc(var(--s) * 1.25) calc(var(--s) * 1.5);
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    padding: calc(var(--s)* 1.25) 0;

    span {
      text-decoration: underline;
    }

    > * {
      color: var(--color-primary);
    }

    &:disabled {
      opacity: 0.5;
    }

    .dynicon {
      --color: var(--color-primary);
      margin-right: var(--s);
    }

    &:hover{
      background-color: transparent;
    }
  }

  &.button--selector {
    padding: calc(var(--s) * 0.75) calc(var(--s) * 2);
    height: calc(var(--s) * 4);
    background-color: transparent;
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    font-weight: 500;

    border: 1px solid var(--color-neutral-variant-50);
    border-radius: var(--s);
    font-family: var(--font-primary);
    color: var(--color-neutral-variant-30);

    cursor: pointer;

    @include dawuti.mq-md {
      margin-bottom: 0;
    }

    &:not([disabled]):hover {
      background-color: var(--color-button-outline-hover);
    }

    &:not([disabled]):active {
      background-color: var(--color-button-outline-focus);
    }

    .dynicon {
      --color: var(--color-text);
      &:first-child {
        margin-left: calc(-1 * var(--s));
        margin-right: var(--s);
      }
      &:last-child {
        margin-left: var(--s);
        margin-right: calc(-1 * var(--s));
      }
    }

    .text {
      color: var(--color-neutral-variant-30);
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  &.button--icon-close {
    padding: 0;
    margin: 0;
    margin-left: auto;
    aspect-ratio: 1/1;
    height: calc(var(--s) * 3);
    background: transparent;
    &:not([disabled]):hover {
      background: transparent;
      opacity: 0.5;
    }
    &:not([disabled]):active {
      background: transparent;
    }
    .dynicon {
      margin: 0;
      --color: var(--color-text);
      --size: calc(var(--s) * 3);
    }
  }

  &.button--suffix {
    margin-right: var(--s);
  }

  &.button--small {
    height: var(--button-small-height);
    padding: 0 calc(var(--s) * 2);
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    span {
      font-size: var(--font-size-small);
      line-height: var(--line-height-small);
    }
    .dynicon {
      --size: 12px;
      margin-right: calc(var(--s) / 2);
    }
  }

  &.button--edercare {
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    font-weight: 500;

    span {
      font-size: var(--font-size-small);
      line-height: var(--line-height-small);
      font-weight: 500;
    }
    .dynicon {
      --color: var(--color-primary);
      --size: calc(var(--s) * 3);
    }

    &.button--fill {
      background: var(--color-primary-gradient-e);
      color: var(--color-light);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      &:not([disabled]):hover {
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
      }
      &:not([disabled]):active {
        filter: brightness(95%);
      }
      .dynicon {
        --color: var(--color-light);
      }
    }

    &.button--light {
      background: var(--color-light);
      color: var(--color-primary);
    }

    &.button--outline {
      color: var(--color-primary);
      border: 1px solid var(--color-primary);

      &:not([disabled]):hover {
        background: var(--color-button-hover);
        border: 1px solid var(--color-primary);
      }
      &:not([disabled]):active {
        background: var(--color-button-focus);
        border: 1px solid var(--color-primary);
      }
    }
    &.button--clear {
      background: transparent;
      color: var(--color-primary);

      &:not([disabled]):hover {
        background: var(--color-button-hover);
      }
      &:not([disabled]):active {
        background: var(--color-button-focus);
      }

      &.button--light {
        color: var(--color-light);
        .dynicon {
          --color: var(--color-light);
        }
        &:not([disabled]):hover {
          background-color: var(--color-primary-rgb-50);
        }
        &:not([disabled]):active {
          background-color: var(--color-primary-rgb-80);
        }
      }
    }
    &.button--icon {
      .dynicon {
        margin: 0;
      }

      &:not([disabled]):hover {
        background: var(--color-button-hover);
      }
      &:not([disabled]):active {
        background: var(--color-button-focus);
      }
      &.button--light {
        background: none;
        color: var(--color-light);
        .dynicon {
          --color: var(--color-light);
        }
        &:not([disabled]):hover {
          background-color: var(--color-primary-rgb-50);
        }
        &:not([disabled]):active {
          background-color: var(--color-primary-rgb-80);
        }
      }
    }
    &.button--clear-neutral {
      background: transparent;
      color: var(--color-text);
      .dynicon {
        --color: var(--color-text);
      }

      &:not([disabled]) {
        &:hover {
          background-color: var(--color-button-neutral-hover);
        }
        &:active {
          background-color: var(--color-button-neutral-active);
        }
      }
    }

    &.button--list {
      background: none;
      color: var(--color-text);
      width: 100%;
      border-radius: 0;
      justify-content: flex-start;
      height: 64px;

      &:not([disabled]):hover {
        color: var(--color-primary);
        .dynicon {
          --color: var(--color-primary);
        }
      }

      & + .button--list {
        border-top: var(--border);
      }

      .dynicon {
        --color: var(--color-text);
        margin-right: calc(var(--s) * 2);
      }
    }

    &.button--link {
      background: none;

      span {
        color: var(--color-text);
        text-decoration: underline;
      }
      &:not([disabled]):hover {
        opacity: 0.5;
      }

      &.button--light {
        * {
          color: var(--color-light);
        }
        &:not([disabled]):hover {
          opacity: 0.5;
        }
      }
    }
  }

  &.button--dropdown {
    width: 100%;
    height: 48px;
    border-radius: 0;
    border: none;
    background-color: var(--color-light);
    color: var(--color-text);
    cursor: pointer;
    text-align: left;
    padding: 0 calc(var(--s) * 2);

    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:not([disabled]):hover {
      background-color: var(--mat-option-focus-state-layer-color);
    }

    span {
      font-weight: 400;
    }
    .dynicon {
      margin-left: var(--s);
    }
  }
}

.button-back {
  background: none;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .dynicon {
    --size: 24px;
    @include mixins.defaultTransition;
  }
  &:hover {
    .dynicon {
      --color: var(--color-neutral-variant-50);
    }
  }
  &.button--light {
    .dynicon {
     --color: var(--color-light);
    }
  }
}
