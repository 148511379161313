@use 'sass:math';
@use 'dawuti.config' as dawuti;

$html-font-size: 16px;
@function stripUnit($value) {
  @return math.div($value, ($value * 0 + 1));
}
@function rem($pxValue) {
  @return #{math.div(stripUnit($pxValue), stripUnit($html-font-size))}rem;
}

:root {
  --font-primary: 'Roboto';
  --html-font-size: #{$html-font-size};

  --font-size-xxsmall: #{rem(11px)};
  --line-height-xxsmall: #{rem(16px)};

  --font-size-xsmall: #{rem(12px)};
  --line-height-xsmall: #{rem(16px)};

  --font-size-small: #{rem(14px)};
  --line-height-small: #{rem(20px)};

  --font-size-base: #{rem(16px)};
  --line-height-base: #{rem(24px)};

  --font-size-bigger: #{rem(18px)};
  --line-height-bigger: #{rem(24px)};

  --font-size-medium: #{rem(22px)};
  --line-height-medium: #{rem(28px)};

  --font-size-large: #{rem(24px)};
  --line-height-large: #{rem(32px)};

  --font-size-xlarge: #{rem(28px)};
  --line-height-xlarge: #{rem(36px)};
  --font-weight-h2: 500;

  --font-size-xxlarge: #{rem(32px)};
  --line-height-xxlarge: #{rem(40px)};
  --font-weight-h1: 600;

  --font-size-huge: #{rem(36px)};
  --line-height-huge: #{rem(44px)};

  --font-size-display-large: #{rem(64px)};
  --line-height-display-large: #{rem(74px)};

  --font-size-display-medium: #{rem(57px)};
  --line-height-display-medium: #{rem(64px)};

  --font-size-display-small: #{rem(45px)};
  --line-height-display-small: #{rem(52px)};

  --profile-heading-font-size: #{rem(32px)};
  --profile-heading-line-height: #{rem(40px)};

  --color-light: #fff;

  --color-primary: #1260a4;
  --color-primary-10: #001c39;
  --color-primary-20: #00315c;
  --color-primary-30: #004883;
  --color-primary-40: #1260A4;
  --color-primary-50: #3879BF;
  --color-primary-60: #5693DB;
  --color-primary-70: #72AEF7;
  --color-primary-80: #9fc9ff;
  --color-primary-90: #d2e4ff;
  --color-primary-95: #e9f1ff;
  --color-primary-99: #FCFDFF;

  --color-primary-10-00: #001c39;
  --color-primary-10-08: #001c3914;
  --color-primary-10-50: #001c3950;
  --color-primary-40-16: #1260a429;

  --color-secondary-10: #341100;
  --color-secondary-20: #552100;
  --color-secondary-30: #783100;
  --color-secondary-40: #9e4300;
  --color-secondary-50: #c55500;
  --color-secondary-60: #ea6c10;
  --color-secondary-70: #ff8d47;
  --color-secondary-80: #ffb68d;
  --color-secondary-90: #ffdbc8;
  --color-secondary-95: #ffede4;
  --color-secondary-99: #fffbf9;

  --color-secondary: var(--color-secondary-70);

  --color-tertiary-10: #07200b;
  --color-tertiary-20: #00390d;
  --color-tertiary-30: #005317;
  --color-tertiary-40: #006e22;
  --color-tertiary-50: #098a33;
  --color-tertiary-60: #34a54a;
  --color-tertiary-70: #53c162;
  --color-tertiary-80: #6fdd7a;
  --color-tertiary-90: #8bfb94;
  --color-tertiary-95: #d9ffd9;
  --color-tertiary-99: #f4ffef;

  --color-tertiary: var(--color-tertiary-50);

  --color-tertiary-40-15: #006e2226;
  --color-tertiary-40-10: #006e22;

  --color-neutral-10: #1e1b16;
  --color-neutral-20: #33302a;
  --color-neutral-30: #4a4640;
  --color-neutral-40: #625e57;
  --color-neutral-50: #7b766f;
  --color-neutral-60: #969088;
  --color-neutral-70: #b0aaa2;
  --color-neutral-80: #ccc5bd;
  --color-neutral-90: #e8e1d8;
  --color-neutral-95: #f8f0e7;
  --color-neutral-99: #fffbf8;

  --color-neutral-variant: #5a5e66;
  --color-neutral-variant-30: #43474e;
  --color-neutral-variant-40: #5a5e66;
  --color-neutral-variant-50: #73777f;
  --color-neutral-variant-60: #8d9199;
  --color-neutral-variant-70: #a7abb3;
  --color-neutral-variant-80: #c3c6cf;
  --color-neutral-variant-90: #dfe2eb;
  --color-neutral-variant-95: #edf0f9;
  --color-neutral-variant-99: #fcfdff;

  --color-neutral-variant-90-30: #f5f6f9;

  --color-custom-turquoise-10: #00201b;
  --color-custom-turquoise-20: #003730;
  --color-custom-turquoise-30: #005047;
  --color-custom-turquoise-40: #006b5f;
  --color-custom-turquoise-95: #008678;
  --color-custom-turquoise-60: #00a392;
  --color-custom-turquoise-70: #00c0ad;
  --color-custom-turquoise-80: #00dec9;
  --color-custom-turquoise-90: #48fbe5;
  --color-custom-turquoise-95: #b1fff3;
  --color-custom-turquoise-99: #f0fffc;

  --color-custom-yellow-10: #2a1800;
  --color-custom-yellow-20: #462b00;
  --color-custom-yellow-30: #633f00;
  --color-custom-yellow-40: #835500;
  --color-custom-yellow-50: #a36b00;
  --color-custom-yellow-60: #c48303;
  --color-custom-yellow-70: #e39d28;
  --color-custom-yellow-80: #ffb948;
  --color-custom-yellow-90: #ffddaf;
  --color-custom-yellow-95: #ffeed9;
  --color-custom-yellow-99: #fcfcfc;

  --color-custom-blue-10: #001e31;
  --color-custom-blue-20: #003450;
  --color-custom-blue-30: #004b71;
  --color-custom-blue-40: #006495;
  --color-custom-blue-50: #007eba;
  --color-custom-blue-60: #0099e1;
  --color-custom-blue-70: #2fb5ff;
  --color-custom-blue-80: #8bcdff;
  --color-custom-blue-90: #c9e6ff;
  --color-custom-blue-95: #e4f2ff;
  --color-custom-blue-99: #fbfcff;

  --color-custom-green-10: #0b2000;
  --color-custom-green-20: #163800;
  --color-custom-green-30: #235100;
  --color-custom-green-40: #316b00;
  --color-custom-green-50: #3f8700;
  --color-custom-green-60: #53a300;
  --color-custom-green-70: #6dbf28;
  --color-custom-green-80: #87dc44;
  --color-custom-green-90: #a1f95d;
  --color-custom-green-95: #ceffa0;
  --color-custom-green-99: #f7ffe6;

  --color-custom-violet-10: #140067;
  --color-custom-violet-20: #2700a1;
  --color-custom-violet-30: #3e1eca;
  --color-custom-violet-40: #5741e2;
  --color-custom-violet-50: #705dfc;
  --color-custom-violet-60: #8e7fff;
  --color-custom-violet-70: #aba0ff;
  --color-custom-violet-80: #c7bfff;
  --color-custom-violet-90: #e5deff;
  --color-custom-violet-95: #f4eeff;
  --color-custom-violet-99: #fffbff;

  --color-custom-pink-10: #380038;
  --color-custom-pink-20: #5b005b;
  --color-custom-pink-30: #7f0b7c;
  --color-custom-pink-40: #9c2f96;
  --color-custom-pink-50: #b949b1;
  --color-custom-pink-60: #d864cd;
  --color-custom-pink-70: #f77fea;
  --color-custom-pink-80: #ffaaf4;
  --color-custom-pink-90: #ffd6f6;
  --color-custom-pink-95: #ffebf9;
  --color-custom-pink-99: #fcfcfc;

  --color-custom-orange-10: #3c0700;
  --color-custom-orange-20: #621200;
  --color-custom-orange-30: #842508;
  --color-custom-orange-40: #a43c1f;
  --color-custom-orange-95: #c55435;
  --color-custom-orange-60: #e66d4b;
  --color-custom-orange-70: #ff8b69;
  --color-custom-orange-80: #ffb49e;
  --color-custom-orange-90: #ffdacf;
  --color-custom-orange-95: #ffede7;
  --color-custom-orange-99: #fcfcfc;

  --color-custom-dark-purple: #5741e2;

  --color-custom-carer-green: #6fdd7a;
  --color-custom-carer-green-contrast: #633f00;

  --color-carer-green: var(--color-tertiary-80);
  --color-carer-green-contrast: var(--color-tertiary-30);

  --color-success: #199139;
  --color-success-60: #53c162;
  --color-success-40-16: #d6e8dc;
  --color-warning: #f7bf31;

  --color-error-10: #410001;
  --color-error-20: #680003;
  --color-error-30: #930006;
  --color-error-40: #ba1b1b;
  --color-error-50: #dd3730;
  --color-error-60: #ff5449;
  --color-error-70: #ff897a;
  --color-error-80: #ffb4a9;
  --color-error-90: #ffdad4;
  --color-error-95: #ffede9;
  --color-error-99: #fcfcfc;

  --color-text: var(--color-neutral-20);
  --color-text-light: var(--color-neutral-60);
  --color-text-variant: var(--color-neutral-variant-30);
  --tab-text-color: var(--color-neutral-20);
  --tab-section-color: var(--color-secondary-90);

  // NIGHT MOMENT (CARD) COLORS
  --color-moment-night: var(--color-primary-20);
  --color-moment-secondary-night: var(--color-primary-30);
  --color-moment-tertiary-night: var(--color-primary-40);
  --color-moment-cuaternary-night: var(--color-primary-70);

  // BUTTONS
  --color-button-hover: #256dab;
  --color-button-focus: #2e73af;

  --color-button-outline-hover: #edf1fb;
  --color-button-outline-focus: #e4eafa;


  --color-button-card-emphasys-hover: #cfd7e9;
  --color-button-card-emphasys-focus: #c8d1e8;

  --color-button-secondary-hover: #ecccbc;
  --color-button-secondary-focus: #e3c4b5;

  --color-button-tertiary-hover: #61c66f;
  --color-button-tertiary-focus: #68c875;

  --color-button-custom: #552100;

  --color-chip-enabled-hover: #f0f0f1;
  --color-chip-enabled-focus: #e9e9ea;

  --color-chip-selected-hover: #ecccbc;
  --color-chip-selected-focus: #e3c4b5;

  --color-on-background: rgba(30, 27, 22, 0.6);

  --shadow-elevation-5: 2px 10px 30px 10px rgba(0, 0, 0, 0.05),
    2px 10px 20px rgba(0, 0, 0, 0.03);
  --shadow-elevation-5-hover: 2px 10px 30px 10px rgba(0, 0, 0, 0.1),
    2px 10px 20px rgba(0, 0, 0, 0.06);
  --shadow-elevation-4: 0px 6px 10px 4px rgba(0, 0, 0, 0.03),
    0px 2px 3px rgba(0, 0, 0, 0.05);
  --shadow-elevation-3: 0px 4px 8px 3px rgba(0, 0, 0, 0.03),
    0px 1px 3px rgba(0, 0, 0, 0.06);
  --shadow-elevation-2: 0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 2px 6px 2px rgba(0, 0, 0, 0.05);
  --shadow-elevation-2-hover: 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 3px 8px 3px rgba(0, 0, 0, 0.06);  ;
  --shadow-elevation-1: 0px 1px 2px rgba(0, 0, 0, 0.05),
    0px 1px 3px 1px rgba(0, 0, 0, 0.03);

  --header-height: 74px;

  @include dawuti.mq-md {
    --header-height: 100px;
  }

  --toolbar-top-height: 74px;
  @include dawuti.mq-md {
    --toolbar-top-height: 100px;
  }

  --toolbar-height: 88px;
  --toolbar-width: 0px;
  @include dawuti.mq-md {
    --toolbar-height: 0px;
    --toolbar-width: 240px;
  }
  @include dawuti.mq-xl {
    --toolbar-width: 358px;
  }
  --toolbar-icon-color: var(--color-secondary-10);
  --toolbar-links-color: var(--color-neutral-variant-50);
  --toolbar-links-color-active: var(--color-secondary-10);
  --toolbar-icons-color: var(--color-secondary-10);

  --form-field-border-radius: 4px 4px 0 0;

  --calendar-header-height: calc(276px + var(--ion-safe-area-top, 0));
  --calendar-header-height-collapsed: calc(
    var(--s) * 16 + var(--ion-safe-area-top, 0)
  );
  @include dawuti.mq-md {
    --calendar-header-height: calc(168px + var(--ion-safe-area-top, 0));
  }
  --letter-spacing-wide: 0.5px;
  --letter-spacing-medium: 0.25px;
  --letter-spacing-narrow: 0.1px;

  --page-padding-top: calc(var(--s) * 4);
  --page-padding-bottom: calc(var(--s) * 7);

  //Elements
  --border-radius: calc(var(--s) * 1.5);
  --border-radius-small: var(--s);
  --border: 1px solid var(--color-neutral-variant-90);
  --border-thick: 2px solid var(--color-neutral-variant-90);
  --border-red: 1px solid var(--color-error-40);

  //Element colors
  --header-welcome-color: var(--color-primary);
  --section-formative-areas-bg-color: var(--color-neutral-95);
  --profile-title-color: var(--color-text);
  --toolbar-bg-color: var(--color-neutral-99);
  --consult-tab-bg-color: var(--color-secondary-90);
  .role-carer {
    --color-secondary-10: #07200b;
    --color-secondary-20: #00390d;
    --color-secondary-30: #005317;
    --color-secondary-40: #006e22;
    --color-secondary-50: #098a33;
    --color-secondary-60: #34a54a;
    --color-secondary-70: #53c162;
    --color-secondary-80: #6fdd7a;
    --color-secondary-90: #8bfb94;
    --color-secondary-95: #d9ffd9;
    --color-secondary-99: #f4ffef;

    //Element colors
    --header-welcome-color: var(--color-secondary-40);
    --section-formative-areas-bg-color: var(--color-secondary-99);
    --profile-title-color: var(--color-secondary-40);
    --toolbar-bg-color: var(--color-secondary-99);
    --consult-tab-bg-color: var(--color-neutral-99);
    --tab-section-color: var(--color-secondary-90);
  }
}
