@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  //no hay RobotoSemibold en Google, asignamos Roboto-Medium en este caso
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}