@use '@angular/material' as mat;
@include mat.core();

$material-primary: (
  50: #e3ecf4,
  100: #b8cfe4,
  200: #89b0d2,
  300: #5990bf,
  400: #3678b2,
  500: #1260a4,
  600: #10589c,
  700: #0d4e92,
  800: #0a4489,
  900: #053378,
  A100: #a7c4ff,
  A200: #74a2ff,
  A400: #4181ff,
  A700: #2770ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$material-accent: (
  50: #ffefe5,
  100: #ffd7bd,
  200: #ffbd91,
  300: #ffa365,
  400: #ff8f44,
  500: #ff7b23,
  600: #ff731f,
  700: #ff681a,
  800: #ff5e15,
  900: #ff4b0c,
  A100: #ffffff,
  A200: #fff8f6,
  A400: #ffd0c3,
  A700: #ffbca9,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$my-primary: mat.define-palette($material-primary, 500);
$my-accent: mat.define-palette($material-accent, A200, A100, A400);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

@include mat.all-component-themes($my-theme);


mat-checkbox.mat-mdc-checkbox.mat-accent .mdc-checkbox__background {
  --mdc-checkbox-selected-checkmark-color: var(--color-light);
  --mdc-checkbox-selected-icon-color: var(--color-tertiary);
  --mdc-checkbox-selected-focus-icon-color: var(--color-tertiary-60);
  --mdc-checkbox-selected-hover-icon-color: var(--color-tertiary-60);
  --mdc-checkbox-selected-pressed-icon-color: var(--color-tertiary-70);
}

mat-checkbox.mat-mdc-checkbox.mat-primary .mdc-checkbox__background {
  --mdc-checkbox-selected-checkmark-color: var(--color-light);
  --mdc-checkbox-selected-icon-color: var(--color-primary-20);
  --mdc-checkbox-selected-focus-icon-color: var(--color-primary-30);
  --mdc-checkbox-selected-hover-icon-color: var(--color-primary-30);
  --mdc-checkbox-selected-pressed-icon-color: var(--color-primary-40);

}

mat-checkbox.input--edercare.mat-mdc-checkbox.mat-primary
  .mdc-checkbox__background {
  --mdc-checkbox-selected-checkmark-color: var(--color-light);
  --mdc-checkbox-selected-icon-color: var(--color-neutral-20);
  --mdc-checkbox-selected-focus-icon-color: var(--color-neutral-30);
  --mdc-checkbox-selected-hover-icon-color: var(--color-neutral-30);
  --mdc-checkbox-selected-pressed-icon-color: var(--color-neutral-40);
}

mat-checkbox.mat-mdc-checkbox.input--edercare {

  --mdc-typography-body2-font-size: 12px;

  .mdc-checkbox__background {
    --mdc-checkbox-selected-checkmark-color: var(--color-light);
    --mdc-checkbox-selected-icon-color: var(--color-primary);
    --mdc-checkbox-selected-focus-icon-color: var(--color-primary);
    --mdc-checkbox-selected-hover-icon-color: var(--color-primary);
    --mdc-checkbox-selected-pressed-icon-color: var(--color-primary);
  }
}

.mat-mdc-checkbox-label {
  white-space: break-spaces;
}
.mat-mdc-checkbox-layout {
  align-items: flex-start !important;
  // padding-top: calc(var(--s) * 1.5);
  .mat-mdc-checkbox-inner-container {
    margin-top: calc(var(--s) / 2);
    margin-bottom: calc(var(--s) / 2);
    margin-right: calc(var(--s) * 1.5);
  }
}

//PAGINATOR

.mat-paginator-container {
  justify-content: center !important;
  .mat-button-base {
    span {
      color: var(--color-text) !important;
    }
    &.mat-button-disabled {
      opacity: 0.3;
    }
  }
  .mat-paginator-page-size {
    display: none !important;
  }

  .mat-paginator-range-actions {
    .mat-paginator-range-label {
      font-size: 14px;
      order: 1;
    }
    > button {
      &:first-child {
        order: 0;
      }
      &:last-child {
        order: 1;
      }
    }
  }
}

// SNACKBAR
.mat-mdc-snack-bar-container {
  margin: 0 !important;
  padding: 0 !important;
  background: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  .mdc-snackbar__surface {
    --mdc-snackbar-container-color: transparent;
    box-shadow: none !important;
  }
}

// RADIO BUTTONS

mat-radio-button .mdc-radio__background {
  --mdc-radio-selected-icon-color: var(--color-neutral-20);
  --mdc-radio-selected-hover-icon-color: var(--color-neutral-30);
  --mdc-radio-selected-focus-icon-color: var(--color-neutral-30);
  --mdc-radio-selected-pressed-icon-color: var(--color-neutral-40);
}
.input--edercare {
  mat-radio-button {
    .mdc-radio__background {
      --mdc-radio-selected-icon-color: var(--color-primary);
      --mdc-radio-selected-hover-icon-color: var(--color-primary-40);
      --mdc-radio-selected-focus-icon-color: var(--color-primary-30);
      --mdc-radio-selected-pressed-icon-color: var(--color-neutral-e-30);
    }
    .mdc-label {
      font-size: 12px;
      line-height: 20px;
    }
  }
}
// PROGRESS BAR
.mat-progress-bar {
  height: var(--s) !important;
}
.mat-progress-bar-background {
  fill: var(--color-neutral-variant-95);
}
.mat-progress-bar-buffer {
  background-color: var(--color-neutral-variant-95);
}
.mat-progress-bar-fill::after {
  background-color: var(--color-primary);
}

// BOTTOM SHEET
mat-bottom-sheet-container.mat-bottom-sheet-container {
  padding: 0;
}

mat-form-field {
  &.mat-form-field-appearance-fill {
    border-radius: var(--form-field-border-radius);
    .mat-mdc-text-field-wrapper {
      background-color: var(--color-primary-95);
      &.mdc-text-field--disabled {
        background-color: var(--color-neutral-variant-95);
      }
    }
    .mat-mdc-form-field-flex {
      .mat-select-arrow-wrapper {
        transform: translateY(0);
      }
      textarea {
        min-height: 200px;
      }
    }

    .mat-mdc-form-field-label {
      color: var(--color-neutral-variant-30);
    }
    .mat-mdc-form-field-wrapper {
      margin-bottom: 10px;
    }
  }
  &.mat-form-field-appearance-fill.transparent {
    .mat-mdc-text-field-wrapper {
      background-color: transparent;
    }
  }

  &.input--edercare {
    --mdc-theme-primary: var(--color-primary);

    --mdc-shape-small: 28px;

    --mat-select-trigger-text-size: 12px;




    --mdc-typography-body1-font-size: 12px;

    .mat-datepicker-toggle-active {
      --mdc-icon-button-icon-color: var(--color-primary);
    }

    &.mat-focused:not(.mat-form-field-invalid) {
      .mdc-floating-label {
        color: var(--color-primary);
      }
    }
    .mdc-floating-label.mat-mdc-floating-label {
      font-size: 12px;
      line-height: 2em;
    }

    .mdc-floating-label.mat-mdc-floating-label.mdc-floating-label--float-above {
      font-size: 12px;
    }

    .mat-mdc-form-field-icon-suffix {
      padding-right: var(--s);
    }

    mat-error.mat-mdc-form-field-error {
      .text {
        font-size: 10px;
        padding-top: 0.25em;
        color: var(--color-error);
      }

      .dynicon {
        --color: var(--color-primary);
      }
    }

    &.input-white {
      * {
        color: var(--color-light) !important;
        border-color: var(--color-light) !important;
      }
      .mat-mdc-input-element {
        caret-color: var(--color-light);
        &::placeholder {
          color: var(--color-light);
        }

        &::-ms-input-placeholder { /* Edge 12 -18 */
          color: var(--color-light);
        }
      }
      mat-error.mat-mdc-form-field-error {
        .text {
          color: var(--color-light);
        }
        .dynicon {
          --color: var(--color-light);
        }
      }
    }
  }
}

mat-select {
  --mat-select-focused-arrow-color: var(--color-primary);
}

mat-option.mat-mdc-option {
  padding-top: calc(var(--s)*2);
  padding-bottom: calc(var(--s)*2);
  &.input--edercare {

    font-size: 12px;
    --mat-option-selected-state-label-text-color: var(--color-primary);

    .mat-pseudo-checkbox {
      &::after {
        color: var(--color-primary);
      }
    }
  }

  &.suboption {
    padding-left: calc(var(--s) * 4);
  }
}

.mat-mdc-select-panel.input--edercare {
  border-radius: var(--border-radius) !important;
}

mat-error.mat-mdc-form-field-error {
  margin-top: calc(var(--s) / 2);
  display: flex;
  align-items: center;
  .dynicon {
    --size: calc(var(--s) * 2);
    --color: var(--color-error);
    margin-right: calc(var(--s) / 2);
  }
}

.mat-mdc-select-arrow {
  width: 24px !important;
  height: 24px !important;
}
