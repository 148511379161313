:root {
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes collapse {
    0% {
      max-height: 150px;
      overflow: hidden;
    }
    100% {
      max-height: 0;
      padding: 0;
      margin: 0;
    }
  }

  @keyframes uncollapse {
    0% {
      max-height: 0;
      padding: 0;
      margin: 0;
    }
    100% {
      max-height: 150px;
      overflow: hidden;
    }
  }

  @keyframes translate-origin-y {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0%);
      opacity: 1;
    
    }
  }
  @keyframes translate-dropdown-y {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0%);
      opacity: 1;
    
    }
  }

  @keyframes translate-full-y {
    from {
      transform: translateY(0%);
      opacity: 1;
    }
    to {
      transform: translateY(100%);
      opacity: 0;
      height: 0;
    }
  }

  @keyframes skeleton-animation {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(50%);
    }
  }

  @keyframes grow {
    0% {
      max-height: 0;
    }
    100% {
      max-height: var(--animation-max-height);
    }
  }

  @keyframes shrink {
    0% {
      max-height: var(--animation-max-height);
    }
    99.999% {
      max-height: var(--animation-min-height, 0);
    }
    100% {
      max-height: 0;
    }
  }

  .animation--fade-in {
    opacity: 0;
    animation: 0.25s fade-in forwards ease-out;
  }
  .animation--expand {
    animation: 0.25s translate-origin-y forwards ease-out;
  }
  .animation--expand-dropdown {
    animation: 0.25s translate-dropdown-y forwards ease;
  }
  .animation--collapse {
    animation: 0.25s translate-full-y forwards ease-in;
  }
}
