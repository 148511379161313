.custom-toggle {
    flex-shrink: 0;
  height: 28px;
  //  TODO add grow on toggle
  //   --handle-width: 16px;
  //   --handle-height: 16px;
  //   --handle-spacing: var(--s);

  --handle-width: 24px;
  --handle-height: 24px;

  --handle-spacing: calc(var(--s) / 4);
  --handle-background: var(--color-neutral-variant-90);
  --track-background-checked: var(--color-primary-80);
  --track-background: transparent;
  --handle-box-shadow: none;
  --handle-background-checked: var(--color-primary)
    url('/assets/icons/check_white.svg') no-repeat center / contain;

  border-radius: calc(var(--s) * 2);
  border: var(--border-thick);
  &::ng-deep .toggle-icon {
    height: 28px;
  }
}
.toggle-checked {
  border-color: var(--color-primary-80);
}
