@use 'dawuti.config' as dawuti;
@use 'theme/mixins' as mixins;

.cdk-overlay-pane.mat-datepicker-popup {
  @include dawuti.mq-xs(down) {
    width: 100%;
    right: 0 !important;
    left: 0 !important;
  }
}

.mat-datepicker-content {
  background-color: transparent;
  box-shadow: none;

  &.mat-datepicker-content::before {
    box-shadow: none;
  }
}

.mat-calendar {
  background-color: var(--color-primary-90);
  padding: calc(var(--s) * 2);
  padding-top: var(--s);
  border-radius: var(--border-radius);


  .mat-calendar-content {
    padding: 0;
  }

  .mat-calendar-body-label {
    opacity: 0;
    padding: 0 !important;
  }

  .mat-calendar-table-header {
    th {
      letter-spacing: 0.1px;
      opacity: 0.4;
      font-weight: 500;
      font-size: var(--font-size-small);
      line-height: var(--line-height-small);
    }
  }

  .mat-calendar-body-cell {

  
    &.mat-calendar-body-active {
      .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
        background: var(--color-primary-95);
      }
    }
    .mat-calendar-body-cell-content {
      @include mixins.defaultTransition();
    }
    .mat-calendar-body-selected {
      background-color: var(--color-primary);
      color: var(--color-light);
    }

    .mat-calendar-body-today.mat-calendar-body-cell-content:not(
        .mat-calendar-body-selected
      ):not(.mat-calendar-body-comparison-identical) {
      border: 2px solid var(--color-primary);
    }

    .mat-calendar-body-selected.mat-calendar-body-today {
      background-color: var(--color-primary);
      color: var(--color-light);
    }

    &:hover {
      .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
          .mat-calendar-body-comparison-identical
        ) {
        background-color: var(--color-primary-80);
      }
    }

    &.is-highlighted {
      .mat-calendar-body-cell-content {
        background-color: var(--color-light);
        color: var(--color-primary);
        &.mat-calendar-body-today {
          border: 2px solid var(--color-primary);
        }
        &.mat-calendar-body-today.mat-calendar-body-selected {
          box-shadow: none;
          border: none;
        }
      }
    }
  }

  &.input--edercare {
    box-shadow: var(--shadow-elevation-2);
    background-color: var(--color-light);
    border-radius: var(--border-radius);
    --mdc-text-button-container-shape: 18px;

    .mat-calendar-body-cell {
      &.mat-calendar-body-active {
        .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
          background: var(--color-primary-95);
        }
      }
      .mat-calendar-body-selected {
        background: var(--color-primary-gradient-e);
        color: var(--color-light);
      }

      .mat-calendar-body-today.mat-calendar-body-cell-content:not(
          .mat-calendar-body-selected
        ):not(.mat-calendar-body-comparison-identical) {
        border: 2px solid var(--color-primary);
      }

      .mat-calendar-body-selected.mat-calendar-body-today {
        background: var(--color-primary-gradient-e);
        color: var(--color-light);
      }

      &:hover {
        .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
            .mat-calendar-body-comparison-identical
          ) {
          background: var(--color-primary-95);
        }
      }

      &.is-highlighted {
        .mat-calendar-body-cell-content {
          background: var(--color-light);
          color: var(--color-primary);
          &.mat-calendar-body-today {
            border: 2px solid var(--color-primary);
          }
          &.mat-calendar-body-today.mat-calendar-body-selected {
            box-shadow: none;
            border: none;
          }
        }
      }
    }
  }
}

.picker--light {
  .mat-calendar {
  background-color: var(--color-light);
  padding: calc(var(--s) * 2);
  padding-top: var(--s);
  border-radius: var(--border-radius);
  }
}