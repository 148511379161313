
fieldset {
  border: 0;
  padding: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
  // text-align: center;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-hidden-wrapper {
  input {
    position: absolute;
    opacity: 0;
  }
}

.input {
  border: none;
  border-radius: var(--form-field-border-radius);
  border-bottom: 1px solid black;
  background-color: var(--color-primary-95);
  color: var(--color-text-variant);
  min-height: calc(var(--s) * 7);
  &.input--input-hidden {
    visibility: hidden;
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
}
