@use 'dawuti.config' as dawuti;
:root {
  .mdc-dialog .mdc-dialog__content {
    padding: 0;
    margin: 0;
    cursor: default;
  }

  // Dialog event
  --dialog-max-width: 400px;
  --dialog-width: calc(100% - (var(--s) * 4));
  --dialog-height: calc(100% - (var(--s) * 4));


  --dialog-max-width--large: 50vw;
  --dialog-width--large: 754px;
  --dialog-height--large: calc(100% - (var(--s) * 4));

  // Dialog respiro
  --dialog-respiro-max-width: 357px;
  --dialog-respiro-width: calc(100% - (var(--s) * 4));
  --dialog-respiro-height: calc(100% - (var(--s) * 4));

  //Dialog summary
  --dialog-summary-max-width: 400px;
  --dialog-summary-width: calc(100% - (var(--s) * 4));
  --dialog-summary-height: calc(100% - (var(--s) * 4));

  .dialog {
    display: flex;
    flex-direction: column;

    padding: calc(var(--s) * 3) calc(var(--s) * 3) calc(var(--s) * 4)
      calc(var(--s) * 3);
    .button.button--icon-close {
      margin-left: auto;
    }
  }

  .dialog-panel {
    width: var(--dialog-width);
    max-width: var(--dialog-max-width) !important;
    .mat-mdc-dialog-container {
      --mdc-dialog-container-shape: calc(var(--s) * 2);
    }

    &.dialog-edercare {
      .mat-mdc-dialog-container {
        --mdc-dialog-container-shape: var(--border-radius);
      }
    }
  }

  .dialog-panel--large {
    @include dawuti.mq-md {
      width: var(--dialog-width--large);
      max-width: var(--dialog-max-width--large) !important;
    }
  }

  .dialog-full {
    height: 100vh;
    min-height: 100vh !important;
    width: 100vw;
    min-width: 100vw !important;
    overflow-y: auto;
    .mat-mdc-dialog-container {
      --mdc-dialog-container-shape: 0;
      position: relative;
      > * {
        min-height: 100%;
        justify-content: center;
      }
    }
  }
}
